.App {
  text-align: center;
}

.top-bar {
  position: fixed;
}

@media (max-width: 767px) {
  .top-bar {
    position: sticky;
    top: 0px;
  }
}

@media (max-width: 767px) {
  .main-content {
    padding-top: 0 !important;
    overflow: hidden;
  }
}

.main-content {
  padding-top: 80px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
